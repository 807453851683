import {Injectable} from "@angular/core";

@Injectable()
export class RolesManager {

    private rolesNames = ["PETITIONER", "CERT_MANAGER", "REF_MANAGER", "WAR_MANAGER", "CLIENT", "ADMIN"];
    private roles = [];

    build(document: Document) {
        this.rolesNames.forEach(role => {
            let checkbox = document.getElementById(role) as HTMLInputElement;
            this.checkRole(checkbox);
        });
        return this.roles;
    }

    private checkRole(checkbox) {
        if (checkbox.checked) {
            this.roles.push(checkbox.id);
        }
    }

}
